import "bootstrap/dist/css/bootstrap.min.css";
import Button from "react-bootstrap/Button";
import ContactCard from "./Components/ContactCard";

function App() {
  return (
    <>
      <ContactCard />
    </>
  );
}

export default App;
